#Appbody{
  overflow-x: hidden;
}
.menu-item {
    padding: 0 30px;
    margin: 5px 5px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    border: none;
}
  .menu-item-wrapper.active {

  }
  .menu-item.active {
    border-right: 1px green solid;
    border-left: 1px green solid;
}
   
  .scroll-menu-arrow {
    padding: 20px;
    cursor: pointer;
  }


  .swipeItem {
    position: relative;
    overflow: hidden;
    text-align: center;
  }
  
  .swipeItem-content {
    position: absolute;
    width: 350%;
    height: 40px;
  }
  
  .swipeList {
    list-style-type: none;
  }
  
  .swipeList-addButton {
    font-size: 30px;
    text-align: center;
    width: 350px;
    height: 65px;
    padding: auto;
    background-color: white;
    border: none;
    outline: 1px solid black;
  }

.Swipeline {
  border-right: 2px solid;
  border-left: 2px solid;
  margin: 0 auto;
  position: absolute;
  left: 45%;
  text-align: center;
  width: 10%;
  border-color: green;
  font-size: 22px;
}
.datepicker {
  top: 20%;
}
.datepicker-modal {
  top: 20%;
}
.swipelisthide {
  position: absolute;
  background: white;
  width: 10%;
  right: 0;
  z-index: 1;
}
.swipelistdata {
  width: 65px;
  padding-left: 43px;
}
@media only screen and (max-width: 321px) {
  .swipelistdata {
  padding-left: 33px !important;
}
}