body{
    font-family: 'Fira Sans', sans-serif;
}
input[type=text], input[type=password], input[type=email],input[type=number],input[type=date], .inuppass {
    width: 98%;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    padding-top:5px;
    padding-bottom: 5px;
    position: relative;
    padding-left:10px;
  }
  ::placeholder {
    color: #000;
  }
label {
    width: 100%;
    color:#717275;
}
#form{
    margin-left:10px;
    margin-right:10px;

}
input[type="submit"] {
    width: 100%;
    background: #000;
    color: #fff;
    border: 0;
    margin-top: 25px;
    padding: 10px;
    margin-bottom: 25px;
}
.password__show {
    position: absolute;
    margin-left: -25px;
    margin-top: 11px;
}
.signuplogo{
    width:13%;
}
.carousel-indicators li{
    background-color: #8D8D8D !important;
    width:5px !important;
    height: 5px !important;
}
.carousel-indicators ol li{
    list-style-type:circle;
}
.carousel-item img {
    width: 275px !important;
    height: 155px;
    margin: 0 auto;
}
  .carousel-indicators {
    bottom: -40px !important;
    z-index: 0;
  }
#homebg{
    background-image: url("../assets/img/bg-black.png");
    background-color: #cccccc;
    padding-top: 40px;
    background-size: cover;
    height: 100%;
    width: 100% !important;
}
.hometext {
    color: #fff;
    font-size: 22px;
    padding-left: 20px;
    padding-right: 20px;
}
.hometext span{
    color:#7BD31E;
}
.Rewards {
    padding-top: 20px;
    position: relative;
    padding-left: 20px;
    background: #fff;
}
.homelinkborder {
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    margin-right: 20px;
}

.Rewardsmargin {
    margin-top: 30px;
}
.Rewards span {
    padding-left: 20px;
    font-weight: bold;
    margin-top: 5px;
    position: absolute;
    color:#000;
}
.Rewards img{
    padding-left:10px;
}
.carousel-control-prev, .carousel-control-next {
    display: none !important;
}
img#Proimg {
    width: 90px;
    height: 90px;
    border-radius: 47px;
    margin: 10px auto;
}
#profile{
    text-align: center;
    background-image: url("../assets/img/my-rewards-bg.png");
    padding-bottom: 20px;
    padding-top:20px;
}
.Upgradebtn {
    padding: 10px 13%;
    background: #fff;
    border: 0;
    margin:5px;
    font-weight: bold;
}
.Redeembtn{
    padding: 10px 13%;
    color:#fff;
    background: #000;
    border: 0;
    font-weight: bold;
    margin: 5px;
}
.Redetbtn{
    padding: 10px 20px;
    width: 90%;
    color:#fff;
    background: #000;
    border: 0;
    font-weight: bold;

}
.redeembtndiv {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
#profile h3 {
    color: #fff;
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 22px;
}
.accname{
    color:#fff;
}
.unusedperksection{

}
.unusedpertext{
padding:20px;
font-size: 18px;
font-weight: bold;
}
.unusdedstore {
    box-shadow: 2px 3px 11px #ccc;
    padding: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
    margin-left: 10px;
}
.blogpage {
    box-shadow: 2px 3px 11px #ccc;
    margin-bottom: 20px;
    margin-right: 20px;
    margin-left: 20px;
    min-height: 275px;
}
.blogpage a{
    color: #000;
    text-decoration: none;
}
.unusdedstore .unusednt {

}
td.shopicon {
    text-align: center;
    vertical-align: middle;
}
td.shopicon img {
    margin-right: 20px;
}
.unusednt{

}
.unusedbt{
    font-weight: bold;
}
.redempts {
    color: red;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
}
.redempts-green {
    color: #7ED21F;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
}
.redemdate{
    font-size: 12px;
}
.productimg {
    text-align: center;
}
.productimg img {
    width: 85%;
    height: 200px;
    margin: 0 auto;
}
.newtext {
    position: absolute;
    background: #7ED21F;
    color: #fff;
    padding: 5px;
    margin-top: 25px;
}
.parkdetailstitle{

}
.parkdetailstitle h3 {
    width: 80%;
    float: left;
    padding: 20px;
    font-size: 22px;
    font-weight: bold;
}
.parkdetailspoint {
    float: left;
    width: 100%;
    padding-left: 20px;
    padding-bottom: 20px;
}
.settingelement{

}
.iMnhxj{
    border:0px solid #fff !important;
}
.iMnhxj a{
    color: #8b8a8a !important;
}
.iMnhxj .selected a {
    border-bottom: 1px solid #7ED21F;
    font-weight: bold;
}
.sc-bdVaJa.iMnhxj .selected a {
    color: #7ED21F !important;
    font-size: 18px;
}
.react-tab p {
    margin-top: 25px;
    padding-left: 10px;
    padding-right: 10px;
}

.settingelement {
    padding: 10px 40px;
    border-bottom: 1px solid #ccc;
    font-weight: bold;
}
.settingelement:hover {
    background: #f1f1f1;
}

.parkrecmain {
    background-image: url(../assets/img/bg-green.png);
    position: relative;
    height: 100vh;
}
.toggle{
    float: right;
}
.parkbody {
    background-image: url(../assets/img/Perk-Receipt.png);
    margin: 100px 20px;
    background: #fff;
}
.packdetailsbody{
    text-align: left;
    padding-left:10px;
}
div.parkbody {
    width: 85%;
    margin: 0 auto;
    position: unset;
    padding-bottom: 10px;
    text-align: center;
    margin-top: 40px;
}
.parkbody h3 {
    border-bottom: 1px dashed green;
    padding-bottom: 20px;
    margin-bottom: 15px;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
}
.PerkReceiptBodymargin {
    padding: 10px;
}
.parkbody img {
    margin-top: -33px;
}
.parkitemtitle {
    color: #ADAFBC;
    font-weight: bold;
}
.perkvalue {
    font-weight: bold;
    margin-top: -10px;
}
.valid {
    float: right;
    padding: 5px 10px;
    margin-top: -32px;
    margin-right: 20px;
    background: #7DD21F;
    color: #fff;
}
.reddetailsbtnconf {
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 90%;
    margin-top: 20px;
    background: #000;
    color: #fff;
    border:0;
}
.reddetailsbtndont{
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 90%;
    background: transparent;
    border: 1px solid #fff;
    color:#fff;
}
.perkbutton {
    text-align: center;
}
.redeemproduct {
    text-align: center;
    box-shadow: 0px 4px 9px #ccc;
    margin: 10px;
    padding: 5px;
}
.redeemproduct img {
    width: 90%;
    height: 200px;
    margin: 0 auto;
}
.redeemproduct p{
    text-align: left;
    padding-left: 20px;
}
.redprotitle{
    font-weight: bold;
    padding-top: 10px;

}
.redpropint{

}
.redprodetails{
    position: absolute;
    right:0;
    margin-right: 20px;
    background: #000;
    color:#fff;
    padding: 10px;
    margin-top: -15px;

}
.unusedperk {
    background-image: url(../assets/img/bg_pattern_grey.png);
    position: relative;
}
.unusedredoday {
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 90%;
    margin-top: 60px;
    background: #000;
    color: #fff;
    border:0;
}
.redeemedtext {
    float: right;
    padding: 5px 10px;
    margin-top: -32px;
    margin-right: 20px;
    background: #7DD21F;
    color: #fff;
}
.UpgradeTier {
    background-image: url(../assets/img/bg-green.png);
    padding-bottom: 100px;
    overflow-x: hidden;

}
.Upgradepack{
    width:80%;
    margin: 0 auto;
    background:#fff;
    padding:15px;
    height: 245px;
}
.upmembername{
    text-align: center;
    color:#A0A0A0;

}
.upmemberpoint {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}
.Upgradepack img {
    position: absolute;
    margin-top: 5px;
}
.upmembervapmod {
    margin-left: 40px;
    margin-top: 5px;
    font-weight: bold;
}
.uppackdetails{
    margin-left: 40px;
}
.upmembdetails {
    text-align: center;
    color: #fff;
    padding: 30px;
    margin: 0 auto;
}
.upgslider{
    position: relative;

}
.slick-dots {
    bottom: 42%;

}
.slick-dots li button:before {
    color: #fff;
}
.slick-dots li.slick-active button:before {
    color: #FCFEFB;
}
.upgradebtn{
    width: 90%;
    padding: 10px 0px;
    margin: 0 auto;
    background: #000;
    color:#fff;
    border:0;
}
.upgradebutton {
    text-align: center;
    margin-top: 20%;
}
.upgradememtitle {
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
    padding: 0px 15px;
    font-size: 22px;
}
.profileimg {
    height: 117px;
    position: relative;
}
.editproimg {
    text-align: center;
    margin-top: 20px;
}
#table{
    width: 100%;
}
#table input {
    border: 0;
    text-align: right;
    padding: 5px 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    background: transparent;
}
#table .sptablecl{
    width: 60%;
}
.gender{
    float: right;
    border:0;
}
#table tr {
    border-bottom: 1px solid #ccc;
    height: 60px;
}
#table tr span{
    padding-left: 20px;
    font-weight: bold;
    display: inherit;
}
.navMenu ul{
    list-style: none;

}
.navMenu ul li {
    list-style: none;
    margin: 15px 0px;
}
.navMenu li a{
    text-decoration: none;
    color:#fff;
    padding: 20px 5px;
}
#navmenubar{
    background: transparent;
    font-size: 20px;
}
#navbtn {
    background: transparent;
    color: #fff;
    border: 0;
    margin:10px 10px 10px 0px;
    position: absolute;
}
#navbtn {
    background: transparent;
    color: #fff;
    border: 0;
    margin:10px 10px 10px 0px;
    position: absolute;
}
.hometextdiv{
    margin-bottom: 20px;
}

.sliding-menu {
    position: absolute;
    top: 0px;
    left: -240px;
    display: flex;
    flex-flow: column nowrap;
    width: 240px;
    height: 100vh;
    padding: 8px;
    background-color: #000000;
    color: #FFFFFF;
    font-size: 1.5em;
    button {
      background-color: #000000;
      display: inline-block;
      border: none;
      text-align: left;
    }
  }

  .main-container {
    position: relative;
    width: 100%;
    height: 50px;
    background-color: #000;
    > button {
      position: relative;
      top: 50%;
      transform: translate(0%, -50%);
      background-color: #000000;
      color: #FFFFFF;
      border: 1px solid #FFFFFF;
    }
  }

  .slide-menu {
    left: 0px;
  }
.menucss {
    background: #000 !important;
    margin: 0px !important;
}
@media only screen and (min-width: 670px) {
    .menucss {
        margin: 0 auto !important;
        background: #000 !important;
    }
  }

.slide-pane__header{
    display: none !important;
}
.navMenu img {
    margin-left: 45px;
    height: 168px;
    margin-bottom: -41px;
    margin-top: -25px;
}
.upgradeback{
    margin-left: 20px;
}
#menuwhite #navbtn {
    color: black;
}
.Redeemableheader {
    text-align: center;
    font-size: 22px;
    padding-top: 8px;
    font-weight: bold;
}
.redeemeableblock{
    margin-bottom: 20px;
}
.perkcategoryblock{
    margin-bottom: 10px;
    margin-left: 10px;
    width: 46%;
}
.perkcategoryblock img { 
    max-width:100%; 
    height:auto; 
    border: 2px solid #000;
} 
.bloghead{
    margin-bottom: 20px;
}
.redeemproduct a{
    color:#000;
    text-decoration: none;
}
.redeemproduct a:hover{
    text-decoration: none;

}
.unusdedstore a{
    color:#000;
}
.unusdedstore a:hover{
    text-decoration: none;
}
.backiconleft{
    text-align: left;
}
.welcomeh2 {
    color: #fff;
    padding: 10px;
    text-align: center;
}
.welcomebody {
    color: #fff;
    text-align: center;
}
.welcomejointext{
    color:#fff;
    margin-top:20px;
    margin-bottom: 20px;
}
.welcreate{
    text-align: center;
    color:#000;
    background: #fff;
    width: 80%;
    margin:0 auto;
    padding:8px;
    font-weight: bold;
}
.Vapinglifeback{
    text-align: center;
    color:#fff;
    width: 80%;
    margin:0 auto;
    padding:8px;
    font-weight: bold;
}
.blogdetails-back-icon{
    text-align: center;
    color: #fff;
    width: 80%;
    margin: 0 auto;
    padding-left: 10px;
    font-weight: bold;
    float: left;
}
.welcreate:hover{
    text-decoration: none;
}
.wellogin, .wellogin a{
    color:#fff;
    text-align: center;
    margin-top:20px;
    padding-bottom: 50px;

}
.wellogin a{
    font-weight: bold;
}
.wellogin a:hover{
text-decoration: none;
color:#fff;
}
.Terms {
    overflow: hidden;
    padding-top: 100%;
    position: relative;
    height: 700px;
  }

  .Terms iframe {
     border: 0;
     height: 100%;
     left: 0;
     bottom: 0;
     right:0;
     position: absolute;
     top: 0;
     width: 100%;
  }
  span.socialicon svg {
    width: 26px;
    margin-left: 10px;
}
.socialsection{
    text-align: center;
    margin-left: 35px;
}

/*Modal*/
.modal-wrapper {
    background: white;
    border: 1px solid #d0cccc;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2), 0 7px 20px 0 rgba(0,0,0,0.17);

    transition: all .8s;
    width: 80%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    border: 0;
    margin-left: auto;
    margin-right: auto;
}

.modal-header {
    background: transparent;
    height: 40px;
    line-height: 40px;
    padding: 5px 20px;
    text-align: right;
    border-bottom: 0px solid #dee2e6 !important;
}

.modal-header h3 {
    color: white;
    float: left;
    margin: 0;
    padding: 0;
}

.modal-body {
    padding: 10px 15px;
    text-align: center;
}

.modal-footer {
    background: #263238;
    height: 35px;
    padding: 15px;
}

.close-modal-btn {
    color: #000;
    cursor: pointer;
    float: right;
    font-size: 30px;
    width: 100%;
    margin-top: -10px;
}

.close-modal-btn:hover {
    color: black;
}

.btn-cancel, .btn-continue {
    background: coral;
    border: none;
    color: white;
    cursor: pointer;
    font-weight: bold;
    outline: none;
    padding: 10px;
}

.btn-cancel {
    background-color: #b71c1c;
    float: left;
}

.btn-continue {
    background-color: #1b5e20;
    float: right;
}

.back-drop {
    height: 100%;
    position: fixed;
    transition: all 1.3s;
    width: 100%;
}

.open-modal-btn {
    margin: 15px;
    padding: 10px;
    font-weight: bold;
}
.storebtn{
   border:0;
   background: #000;
   color:#fff;
   padding: 10px;
   width:90%;
}
.storeinput{
    width: 40% !important;
    margin: 0 auto;
}
.Storecodetxt{
    font-weight: bold;

}
.react-datepicker__input-container {
    position: relative;
    display: block !important;
}
.react-datepicker-wrapper {
    display: block !important;
}
.profilevalright{
    float: right;
    margin-right: 15px;
}
.welcreate i[style]{
    display: contents !important;
}
.proaccountinfo{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}
.Page{
    position:fixed;
    top:50%;
    left:50%;
    text-align: center;
}
.welimg{
    margin-top: 50px;
}
.signupheader{
    font-size: 24px;
}
.welbackground{
    background-image: url(../assets/img/welcome/bg.png);
    background-size: cover;
    height: 100%;
    position: fixed;
}
#menu{
    z-index: 1;
}
@media only screen and (min-width: 600px) {
    #homebg {
        width: 46%;
    }
  }
  .probackground{
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../assets/img/ggmasked.png");
    padding-top: 30px;
  }
.profilehead {
    margin-top: 10px;

}
.IconCamera{
    position: absolute;
    z-index: 2;
}
img.cameraicon {
    margin-top: 60px;
    margin-left: 7px;
}
td.tdwidthlike2 {
    width: 46%;
}
td.tdwidthlike1 {
    width: 1%;
    text-align: center;
    vertical-align: middle;
}
td.tdwidthlike3 {
    width: 21%;
    text-align: center;
    vertical-align: middle;
}
td.tdwidthlike3 {
    width: 20%;
    text-align: center;
    vertical-align: middle;
}
td.tdwidthlike1 img {
    margin-right: 20px;
}
.navMenu {
    margin-left: -70px;
    text-align: center;
}
.iMnhxj .tab {
    margin: 25px 0px 0px 0px !important;
}
img.profileteans {
    text-align: center;
    position: absolute;
    z-index: 2;
    margin-left: -44px;
    width: 176px;
}
.profile-avatar{
    width: 80px;
    height: 80px;
    -webkit-border-radius: 60px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 60px;
    -moz-background-clip: padding;
    border-radius: 60px;
    background-clip: padding-box;
    margin: 0 auto;
    background-size: cover;
    background-position: center center;
}
.blogthumb{
    width: 100%;
    height: 270px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 60px;
    -moz-background-clip: padding;
    background-clip: padding-box;
    margin: 0 auto;
    background-size: cover;
    background-position: center center;
}
.blogthumbtabview{
    width: 100%;
    height: 170px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 60px;
    -moz-background-clip: padding;
    background-clip: padding-box;
    margin: 0 auto;
    background-size: cover;
    background-position: center center;
}
.blognewstitle {
    font-weight: bold;
}
.blogNewsthumb {
    width: 80px;
    height: 80px;
    background-clip: padding-box;
    background-size: cover;
    background-position: center center;
    float: left;
    margin-right: 10px;
}
.blogpageNews a {
    text-decoration: none;
    color: #000;
}
.blogpageNews{
    margin-bottom: 20px;
    margin-right: 10px;
    margin-left: 10px;
}
.pagebackmargin {
    margin-left: 10px;
}
.myrewardsunavailable {
    text-align: center;
    padding: 0px 20px;
}
.notification-icon{
    color: #fff;
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 20px;
}
.blogdetails-home-icon{
    color: #fff;
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 20px;
}
.green-dot{
    background-color: #7CD41D;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    display: inline-block;
}
.td-green-dot{
    width: 5%;
    text-align: center;
    vertical-align: middle;
}
.notification-arrow{
    color: #000
}
.td-notification-arrow{
    width: 20%;
    text-align: right;
    vertical-align: middle;
}
.datepicker-modal {
    top: 35%;
}
button.datepic {
    width: 98%;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
    padding-left: 10px;
    background: #fff;
    text-align: left;
    height: 38px;
}
.blogbody {
    margin: 0 auto;
    text-align: center;
}
.blogbody img {
    margin: 0 auto;
}

.blogMorenews {
    padding: 20px;
}
.vap1title {
    color: #000;
    font-family: 'FiraSans-Bold';
    font-size: 24px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 15px;
}
.vapwelcombody {
    text-align: center;
}
.vapwelbtndiv {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
}
.vapwelcombtn {
    width: 100%;
    border: 0;
    padding: 10px;
    background: #000;
    color: #fff;
}
.vap2body {
    text-align: center;
}
.vap2body img {
    margin-top: -40px;
    margin-bottom: 20px;
}
.vapbtncontinue {
    color: #fff;
    width: 100%;
    border: 0;
    padding: 10px;
    background: #000;
    margin-bottom: 10px;
    margin-top: 20px;
}
.vapbtnback {
    color: #000;
    width: 100%;
    border: 1px solid #000;
    background: #fff;
    padding: 10px;
    margin-bottom: 20px;
}
.slick-slide.slick-active.slick-center.slick-current {
    border-right: 1px solid green;
    text-align: center;
    border-left: 1px solid green;
    color: green;
}
.slick-initialized .slick-slide {
    text-align: center;
}
.vap3div {
    text-align: center;
    margin-top: -25px;
}
.vap3div img {
    margin-bottom: 20px;
}
.vap4div {
    text-align: center;
    margin-top: -25px;
}
.vap4div img {
    margin-bottom: 20px;
}
.Vapinglifebg {
    background-image: url("../assets/img/my-rewards-bg.png");
    background-color: #cccccc;
    padding-bottom: 20px;
}
.vapingheadtext {
    text-align: center;
    color: #fff;
    font-size: 20px;
}
.vapheadbottom {
    background: #fff;
    width: 90%;
    margin: 0 auto;
    font-weight: bold;
}
td.vaptd {
    width: 10%;
}
td.vaptd1 {
    width: 65%;
}
td.vaptd2 {
    width: 17%;
}
table.vaptableele {
    width: 100%;
}
table.vaptableele tr {
    border-bottom: 2px solid #ddd;
}
table.vaptableele tr:last-child {
    border-bottom: 0px solid #ddd;
}
table.vaptableele tr td {
    padding: 15px 5px 15px 10px;
}
.vaphealthmeter {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    position: relative;
}
.healthbody {
    padding: 10px;
    box-shadow: 0px 0px 4px #ccc;
}
td.healthmeterper {
    text-align: center;
    padding-right: 10px;
}
.healthtxt{
    font-weight: bold;
}
td.healthmeterpertxt {
    font-size: 14px;
    vertical-align: top;
}
.heathtitile {
    text-align: center;
    font-size: 22px;
    padding-top: 8px;
    width: 60%;
    margin: 0 auto;
    position: absolute;
    font-weight: bold;
}
.vaping1img {
    text-align: center;
}
td.healthtr1 {
    padding: 20px;
    font-weight: bold;
}
td.healthtr2 {
    padding-right: 20px;
    padding-bottom: 15px;
    padding-top: 15px;
}
table.hdtable tr {
    border-bottom: 1px solid #ccc;
}
table.hdtable {
    margin-top: 30px;
}
.helthdiscla{
    padding: 20px;
}
.blogdetailsback {
    position: absolute;
    top: 10px;
    width: 100%;
}
.blogdetailsimg {
    text-align: center;
    position: relative;
}

.blgodetailsbody h2 {
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}
.blogdetailstext{
    padding:20px;
}
p.vap6day {
    float: left;
}
button.vap6date {
    float: right;
    border: 0;
    background: transparent;
    color: green;
    font-weight: bold;
}
.vapdaydate {
    margin-left: 20px;
    border-bottom: 1px solid #000;
    margin-right: 20px;
    overflow: hidden;
}
.vaptime {
    margin-left: 20px;
    border-bottom: 1px solid #000;
    margin-right: 20px;
    overflow: hidden;
    margin-top: 20px;
}
p.vap6time {
    float: left;
}
button.vap6ctime {
    float: right;
    border: 0;
    background: transparent;
    color: green;
    font-weight: bold;
}
.PerkCategoryGrid{
    display: inline-flex;
    width:100%;
}
.cigarettepack {
    display: inline-flex;
}
.select-number {
    margin: 0 20px;
    border: 0;
    color: green;
    font-weight: bold;
    font-size: 22px;
}
.cigarettecomma {
    margin-top: 22px;
    color: black;
    font-size: 22px;
}

.selectdiv {
    position: relative;
    float: left;
    min-width: 80px;
    margin: 10px;
}
.dropdownsingle {
    position: relative;
    width: 80px;
    margin: 10px auto;
}
  select::-ms-expand {
       display: none;
  }

  .selectdiv:after {
    content: '▼';
    font: 22px "Consolas", monospace;
    color: #333;
    right: 11px;
    top: 16px;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
}
.dropdownsingle:after {
    content: '▼';
    font: 22px "Consolas", monospace;
    color: #333;
    right: 11px;
    top: 16px;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
}

  .selectdiv select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    max-width: 320px;
    height: 50px;
    float: right;
    margin: 5px 0px;
    padding: 0px 24px;
    font-size: 16px;
    line-height: 1.75;
    color: green;
    background-color: transparent;
    background-image: none;
    border-top: 1px solid green;
    -ms-word-break: normal;
    word-break: normal;
    border-bottom: 1px solid green;
}
.dropdownsingle select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    max-width: 320px;
    height: 50px;
    float: right;
    margin: 5px 0px;
    padding: 0px 24px;
    font-size: 16px;
    line-height: 1.75;
    color: green;
    background-color: transparent;
    background-image: none;
    border-top: 1px solid green;
    -ms-word-break: normal;
    word-break: normal;
    border-bottom: 1px solid green;
}
.react-confirm-alert-body {
    width: 100% !important;

}
.blogimage img{
    width: 90%;
    height: 150px;
}
.blogbody h2 {
    font-size: 16px;
    text-align: left;
    font-weight: bold;
    padding: 20px;

}
.blogdetailsimg img {
    width: 100% !important;
}
button.myvaplifebtn {
    background: transparent;
    color: #fff;
    border: 0;
}
.vap1title {
    font-family: 'Fira Sans', sans-serif;
}

.perk-receipt-top {
    color: white;
    text-align: center;
}
.modal-wrapper {
    top: 20%;
}
.selectedUser {
    background-color: rgb(88, 225, 100);
    font-weight: bold;
    margin-right: 20px;
}
.searchbutton {
    background: #7DD21F;
    border: 0;
    color: #02033c;
    font-weight: bold;
    font-size: 14px;
    margin: 10px;
    padding: 5px;
}
.search-li {
    padding: 10px;
    margin-top: 5px;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
}
.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px; /* Optional: to provide some spacing from the top */
}


/* Styles for the impersonation header */
.impersonation-header {
    background-color: rgba(255, 255, 0, 0.875); /* Yellow background */
    padding: 5px; /* Some padding around the content */
    padding-left: 30px;
    position: fixed; /* Fixed at the top of the page */
    top: 0; /* Align to the top */
    /*bottom: 0;  Align to the bottom */
    width: 100%;  /* Take full width */
    box-sizing: border-box; /* Include padding in width calculation */
    /* display: flex; Use flexbox for layout */
    justify-content: space-between; /* Space between text and button */
    align-items: center;  /* Align items vertically */
  }
  
  /* Styles for the text in the header */
  .impersonation-text {
    font-size: small; /* Small font size */
    padding-right: 40px;
  }
  
  /* Styles for the exit button */
  .exit-impersonation-button {
    background-color: rgb(7, 212, 7); /* Clear background */
    border: 1px solid rgb(4, 124, 14); /* Stylish border */
    padding: 3px 20px; /* Padding inside the button */
    cursor: pointer; /* Cursor changes to pointer when hovering */
    font-size: small; /* Small font size for the button text */
  }
  
  .exit-impersonation-button:hover {
    background-color: #f8f8f8; /* Slight background change on hover */
  }
  